<template>
    <div class="hist">
        <div class="hist-warp">
            <div class="roomleft-num">
                <h5>我的客服工单</h5>
                <el-button type="primary" round @click="dialogFormVisible = true">创建客服工单</el-button>
                <h6>
                    客服会在4小时内联系您
                </h6>
            </div>

            <div class="hist-list">
                <el-tabs type="border-card" v-model="activeName">


                    <el-tab-pane label="工单列表" name="one">
                        <div class="bot">
                            <el-table :data="tableData" style="width: 100%" :cell-style="columnStyle">
                                <el-table-column prop="id" label="ID" width="50px">
                                </el-table-column>
                                <el-table-column prop="title" label="工单标题">
                                </el-table-column>
                                <el-table-column prop="type" label="工单类容">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.type == 10">充值问题</span>
                                        <span v-if="scope.row.type == 11">活动咨询</span>
                                        <span v-if="scope.row.type == 1">账号问题</span>
                                        <span v-if="scope.row.type == 2">饰品问题</span>
                                        <span v-if="scope.row.type == 3">对战问题</span>
                                        <span v-if="scope.row.type == 4">对换问题</span>
                                        <span v-if="scope.row.type == 5">取回问题</span>
                                        <span v-if="scope.row.type == 6">网站BUG</span>
                                        <span v-if="scope.row.type == 7">其他问题</span>
                                        <span v-if="scope.row.type == 8">建议反馈</span>
                                        <span v-if="scope.row.type == 9">投诉反馈</span>
                                    </template>
                                   
                                </el-table-column>
                                <el-table-column prop="status" label="工单状态">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.status == 1">待回复</span>
                                        <span v-if="scope.row.status == 2">已回复</span>
                                        <span v-if="scope.row.status == 3">已结束</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="" label="操作">
                                    <template slot-scope="scope">
                                        <el-button v-if="scope.row.status!=3" size="small" type="text"  @click="WakeLock(scope.row)">继续咨询</el-button>
                                        <el-button v-else size="small" type="text" @click="WakeLock(scope.row)">工单已结束</el-button>
                                        <el-button  v-if="scope.row.status==3" size="small" type="text"  @click="deiTicket(scope.row.id)">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="roll-page">
                            <el-pagination background layout="prev, pager, next" :total="totalSize" :page-size="pageSize"
                                @current-change="currentChange">
                            </el-pagination>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- 创建工单弹框 -->
        <el-dialog title="创建客服单" :visible.sync="dialogFormVisible" width="40%" custom-class="customstyle">
            <h4 style="color:#ababab;">请您查阅网页底部《常见问题》指引,提交客服单时请尽量描述清楚问题及需求，客服会在4小时内回复您</h4>
            <el-form :model="form">
                <el-form-item label="问题类型">
                    <el-select v-model="form.type" placeholder="请选择问题类型">
                        <el-option label="充值问题" value="10"></el-option>
                        <el-option label="活动咨询" value="11"></el-option>
                        <el-option label="账号问题" value="1"></el-option>
                        <el-option label="饰品问题" value="2"></el-option>
                        <el-option label="对战问题" value="3"></el-option>
                        <el-option label="兑换问题" value="4"></el-option>
                        <el-option label="取回问题" value="5"></el-option>
                        <el-option label="网站BUG" value="6"></el-option>
                        <el-option label="其他问题" value="7"></el-option>
                        <el-option label="建议反馈" value="8"></el-option>
                        <el-option label="投诉反馈" value="9"></el-option>
                       
                    </el-select>
                </el-form-item>
                <el-form-item label="客服单标题">
                    <el-input v-model="form.title" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="客服单内容">
                    <el-input type="textarea" v-model="form.content"></el-input>
                </el-form-item> -->

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitTicket">提 交</el-button>
            </div>
        </el-dialog>

        <!-- 回复工单 -->
        <el-dialog title="客服单" :visible.sync="dialogFormVisible1" width="40%" custom-class="customstyle">
            <el-form :model="form">
                <el-form-item label="客服单类型">
                    <el-tag v-if="ticket.type==1">账号问题</el-tag>
                    <el-tag v-if="ticket.type==2">饰品问题</el-tag>
                    <el-tag v-if="ticket.type==3">对战问题</el-tag>
                    <el-tag v-if="ticket.type==4">对换问题</el-tag>
                    <el-tag v-if="ticket.type==5">取回问题</el-tag>
                    <el-tag v-if="ticket.type==6">网站BUG</el-tag>
                    <el-tag v-if="ticket.type==7">其他问题</el-tag>
                    <el-tag v-if="ticket.type==8">建议反馈</el-tag>
                    <el-tag v-if="ticket.type==9">投诉反馈</el-tag>
                    <el-tag v-if="ticket.type==10">充值问题</el-tag>
                    <el-tag v-if="ticket.type==11">活动咨询</el-tag>
                </el-form-item>
                <el-form-item label="客服单标题">
                    <el-input :value="ticket.title" disabled autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="客服单内容">
                    <el-input type="textarea" :value="ticket.content" disabled></el-input>
                </el-form-item>
                <el-form-item label="客服回复内容" v-if="ticket.reply_content">
                    <el-input type="textarea" :value="ticket.reply_content" disabled></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible1 = false">关闭</el-button>
               
            </div>
        </el-dialog>
        <!-- 编辑工单 -->
        <chat-room v-show="chatRoom" :ticketobj="ticketobj"  @chat-close="chatClose"></chat-room>
    </div>
</template>
  
<script>
import ChatRoom from "@/components/ChatRoom.vue";
export default {
    components: {
        ChatRoom,
    },
    data() {
        return {
            chatRoom:false,
            activeName: "one",
            totalSize: 0,
            page: 1,
            pageSize: 10,
            tableData: [],
            dialogFormVisible1:false,
            dialogFormVisible: false,
            form: {
                title: '',
                type: '',
                content: ''
            },
            ticket:{},
            ticketobj:{},//工单信息
        };
    },
    mounted() {
        this.getTicketList(this.page);
    },
    methods: {
        //关闭聊天
        chatClose(){
            this.chatRoom = false;
            this.getTicketList(this.page);
        },

         // 获取工单
         getTicketList(page) {
            let param = {
                page: page,
                pageSize: this.pageSize,
            };
            this.$axios
                .post("index/ticket/getList", this.$qs.stringify(param))
                .then((res) => {

                    var data = res.data;
                    if (data.status == "1" && data.data != null) {
                        this.totalSize = data.data.total;
                        this.tableData = data.data.list;                        
                    }
                });
        },

        //提交工单
        submitTicket(){            
            this.$axios
               .post("index/ticket/add", this.$qs.stringify(this.form))
               .then((res) => {
                    var data = res.data;
                    if (data.status == "1") {
                        console.log('res.data.data',data.data);
                        this.$message({
                            type: "success",
                            message: "工单创建成功",
                        });
                        this.ticketobj  = data.data;
                        this.chatRoom = true;
                        this.dialogFormVisible = false;
                        this.getTicketList(this.page);
                    } else {
                        this.$message({
                            type: "error",
                            message: data.msg,
                        });
                    }
                });
        },
        //删除工单
        deiTicket(id){
            this.$confirm("确认删除该工单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                customClass:'message-box'
            })
               .then(() => {
                    this.$axios
                       .post("index/ticket/del", this.$qs.stringify({ id: id }))
                       .then((res) => {
                            var data = res.data;
                            if (data.status == "1") {
                                this.$message({
                                    type: "success",
                                    message: "工单删除成功",
                                });
                                this.getTicketList(this.page);
                            } else {
                                this.$message({
                                    type: "error",
                                    message: data.msg,
                                });
                            }
                        });
                })
               .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },

        //点击查看工单
        WakeLock(row){
            this.ticketobj = row;
            this.chatRoom = true;
            return false;
            // this.dialogFormVisible1 = true;
            // this.ticket = row;
        },

        columnStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 2) {
                //第三第四列的背景色就改变了2和3都是列数的下标
                return "color: #e9b10e ;";
            }
        },
        columnStyle1({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 1) {
                //第三第四列的背景色就改变了2和3都是列数的下标
                return "color: #e9b10e ;";
            }
        },
        columnStyle2({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 4) {
                //第三第四列的背景色就改变了2和3都是列数的下标
                return "color: #e9b10e ;";
            }
            if (columnIndex == 3) {
                return "color: #c3c3e2 ;";
            }

            if (columnIndex == 1) {
                if (row.pay == "支出") {
                    return "color:  #c3c3e2 ;";
                } else {
                    return "color:#02bf4d;";
                }
            }

        },
        //充值流水 页数
        currentChange(val) {
            this.getTicketList(val);
        },
    },
};
</script>
  
<style lang="less" scoped>
/deep/ .el-message-box{
    width: 320px !important;
}
/deep/ .el-dialog__title {
    color: #ffffff !important;
}

/deep/ .el-form-item__label {
    color: #ffffff !important;
}
/deep/ .message-box {
    width: 320px !important;
}


/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.hist {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: #1a1c24;

    .hist-warp {
        padding: 16px;
    }

    //tabs
    .hist-list {
        margin-top: 20px;
    }

    .hist-list /deep/ .el-tabs--border-card {
        background-color: #2b2c37;
        border: none;
    }

    .hist-list /deep/ .el-tabs--border-card>.el-tabs__header {
        border: none;
        background-color: #1a1c24;
    }

    .hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
        background-color: #2b2c37;
        border: none;
    }

    .hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {
        background-color: #24252f;
        border: none;
    }

    .hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
        color: #e9b10e;
    }

    //页数
    .roll-page {
        margin: 10px 0 0 -10px;
    }

    .roll-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #e9b10e;
        color: #1a1c24;
    }

    .roll-page /deep/ .el-pagination.is-background .btn-next,
    /deep/ .el-pagination.is-background .btn-prev,
    /deep/ .el-pagination.is-background .el-pager li {
        background-color: #333542;
        color: #848492;
    }

    //表格
    .bot /deep/ .el-table th,
    .bot /deep/ .el-table tr {
        background-color: #2b2c37;
    }

    .bot /deep/ .el-table td,
    .bot /deep/ .el-table th.is-leaf {
        border-bottom: 1px solid #444659;
    }

    .bot /deep/ .el-table::before {
        height: 0;
    }

    .bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: #212e3e !important;
    }

    /*.bot /deep/ .cell {
      padding: 0;
      height: 60px;
      line-height: 60px;
    }*/

    .roomleft-num {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
            font-size: 20px;
            font-weight: 400;
            color: #c3c3e2;
        }

        h6 {
            font-weight: 200;
            color: #848492;
            font-size: 16px;

            span {
                color: #17b4ed;
            }

            span:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
</style>
  
  