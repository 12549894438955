import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


function getToken() {
  const token = localStorage.getItem('token')
  return token || ''
}


export default new Vuex.Store({
  state: {
    id: localStorage.getItem("id"),
    name: "",
    img: "",
    money: "",
    mobile: "",
    loginState: false,
    informState: true,
    id1: localStorage.getItem("csgoNum"),
    websockReadyState: 2,
    token: getToken(),
  },
  mutations: {
    getId(state, obj) {
      state.id = obj.id;
      state.name = obj.name;
      state.img = obj.img;
      state.money = obj.total_amount;
    },
    getMoney(state, money) {
      state.money = money;
    },
    getLogin(state, loginState) {
      state.loginState = loginState;
    },
    getInform(state, informState) {
      state.informState = informState;
    },
    getWebsock(state, websockReadyState) {
      state.websockReadyState = websockReadyState;
    },
    setMobile(state, value) {
      state.mobile = value
    },
    setUserToken(state, token) {
      localStorage.setItem('token', token)
      state.token = token
    },
    loginOut(state) {
      localStorage.clear();
      state.token = '';
      console.log("===========localStorage.clear==========",state.token=='');
    }
  },
  actions: {
  },
  modules: {
  }
})

//export default store
