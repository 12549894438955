<template>
  <div class="chat-room">
    <div class="room">
      <div v-drag class="head">
        <div class="image">
          <img
            src='/cs2admin/img/logo.48190ca0.png'
          />
        </div>
        <div class="name">平台客服</div>
        <div style="color: #fff; margin-right: 30px">
          咨询类型:
          <el-tag type="danger" v-if="ticketobj.type == 1">账号问题</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 2">饰品问题</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 3">对战问题</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 4">对换问题</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 5">取回问题</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 6">网站BUG</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 7">其他问题</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 8">建议反馈</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 9">投诉反馈</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 10">充值问题</el-tag>
          <el-tag type="danger" v-if="ticketobj.type == 11">活动咨询</el-tag>
        </div>
        <div class="iconfont icon-guanbi5" @click="close">
          <i
            style="color: #fff; font-size: 18px"
            class="el-icon-circle-close"
          ></i>
        </div>
      </div>
      <div class="main">
        <div class="chat">
          <div ref="record" class="record" @scroll="onScroll">
            <ul>
              <template>
                <li style="text-align: center;">工单:{{ ticketobj.title }}</li>
                <li
                  v-for="item in records"
                  :key="item.id"
                  :class="{ right: item.type == 2 }"
                >
                  <div class="time-tag">
                    {{ item.create_time }}
                  </div>
                  <div class="avatar">
                    <img :src="item.avatar" />
                  </div>
                  <div class="content">
                    <div
                      v-if="item.msn_type === 1"
                      class="text"
                      v-html="item.msn"
                    ></div>
                    <div v-if="item.msn_type === 2" class="image">
                      <img :src="item.msn" />
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
          <div class="editor">
            <div class="editor-hd">
              <div>
                <!-- <button title="图片">
                  <el-upload
                    :action="uploadAction"
                    :headers="headers"
                    :show-file-list="false"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :before-upload="beforeUpload"
                  >
                    <i class="el-icon-picture"></i>
                  </el-upload>
                </button> -->
              </div>
              <div>
                <button class="end" @click="shauxin">
                    刷新
                </button>
              </div>
            </div>
            <div class="editor-bd">
              <textarea
                v-model="chatCont"
                :placeholder="ticketobj.status!=3?'请输入文字内容':'该工单已经关闭'"
                @keydown.enter="ctrlEnter"
              ></textarea>
            </div>
            <div class="editor-ft">
              <button :disabled="!chatCont" @click="sendMessage">发送</button>
            </div>
          </div>
        </div>
        <!-- <div class="notice">
          <div v-if="notice" class="rich" v-html="notice"></div>
          <div class="copy">
            <a href="http://www.crmeb.com/" target="_blank"
              >提供技术支持</a
            >
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatRoom",
  auth: false,
  props: {
    ticketobj: {
      type: Object,
      default: {},
    },
  },
  directives: {
    drag: {
      inserted: function (el) {
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        let isDown = false;
        el.onmousedown = function (e) {
          x = e.clientX;
          y = e.clientY;
          l = el.parentNode.offsetLeft;
          t = el.parentNode.offsetTop;
          isDown = true;
          el.style.cursor = "move";
          window.onmousemove = function (e) {
            if (isDown == false) {
              return;
            }
            let nx = e.clientX;
            let ny = e.clientY;
            let nl = nx - (x - l);
            let nt = ny - (y - t);
            el.parentNode.style.left = nl + "px";
            el.parentNode.style.top = nt + "px";
          };
          window.onmouseup = function () {
            isDown = false;
            el.style.cursor = "default";
            window.onmousemove = null;
            window.onmouseup = null;
          };
          return false;
        };
      },
    },
  },
  data() {
    return {
      recordList: [],
      page: 1,
      limit: 20,
      chatCont: "",
      uploadAction: ``,
      headers: null,
      notice: "",
    };
  },

  computed: {
    records() {
      return this.recordList.map((item, index) => {
        return item;
      });
    },
  },
  watch: {
    ticketobj(newVal, oldVal) {
      this.recordList = [];
      console.log("获取工单信息", newVal);
      if (newVal.id > 0) {
        this.getRecordList();
      }else{
        if(oldVal.id > 0) {
          this.getRecordList();
        }
      }
    },
  },
  created() {},
  mounted() {},

  methods: {
    shauxin(){
      let _this = this;
      _this
        .$axios({
          url: "/index/Ticket/getMsg",
          method: "post",
          data: {
            id: this.ticketobj.id,
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            
            if(res.data.data){
              _this.recordList = res.data.data;              
            }
            _this.recordChat();
          } else {
            _this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
    // enter 发送
    ctrlEnter(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
      if (this.chatCont.trim()) {
        this.sendMessage();
      }
    },
    // 关闭聊天窗口
    close() {
      this.$emit("chat-close");
    },

    //滚动
    onScroll(event) {
      // if (event.target.scrollTop <= 30) {
      //   this.getRecordList();
      // }
    },
    // 加载到底部
    recordChat() {
      this.$nextTick(() => {
        this.$refs.record.scrollTop =
          this.$refs.record.scrollHeight - this.$refs.record.clientHeight;
      });
    },

    // 工单记录
    getRecordList() {
      let _this = this;
      _this
        .$axios({
          url: "/index/Ticket/getMsg",
          method: "post",
          data: {
            id: this.ticketobj.id,
          },
        })
        .then((res) => {
          if (res.data.status == 1) {
            if(res.data.data){
              _this.recordList = _this.recordList.concat(res.data.data);
            }

            _this.recordChat();
          } else {
            _this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },

    // 发送消息
    sendMessage() {
      let _this = this;
      if(_this.ticketobj.status ==3){
        _this.$message({
          type: "error",
          message: "该工单已关闭!",
        });
        return;
      }
      if(_this.ticketobj.status ==1){
        _this.$message({
          type: "error",
          message: "请等待平台客服回复!",
        });
        return;
      }
      let sendData = {
        ticket_id: this.ticketobj.id,
        type: 2,
        msn_type: 1,
        msn: this.chatCont,
        avatar: this.ticketobj.img,
        uid: this.ticketobj.player_id
      };
      _this
        .$axios({
          url: "/index/Ticket/addMsg",
          method: "post",
          data: sendData,
        })
        .then((res) => {
          if (res.data.status == 1) {
            _this.chatCont = "";
            _this.recordList = _this.recordList.concat(res.data.data);
            _this.ticketobj.status = 1;
            _this.recordChat();
          } else {
            _this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },

    // 点击结束工单
    chatEnd() {
      let _this = this;
      if(_this.ticketobj.status ==3){
        _this.$message({
          type: "error",
          message: "该工单已关闭!",
        });
        return;
      }
      this.$confirm("确认结束工单吗？,结束后此工单将不能回复")
        .then((_) => {
          _this
            .$axios({
              url: "/index/Ticket/endTicket",
              method: "post",
              data: {
                id: _this.ticketobj.id,
              },
            })
            .then((res) => {
              if (res.data.status == 1) {
                _this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                _this.$emit("chat-close");
              } else {
                _this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });

        })
        .catch((_) => {});
    },
    beforeUpload(file) {
      const isImage = file.type === "image/jpeg" || file.type === "image/png";
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、PNG 格式!");
      }
      return isImage;
    },
    uploadSuccess(response) {
      if (response.status === 200) {
        this.socket.send({
          data: {
            msn: response.data.url,
            type: 3,
            to_uid: this.toUid,
          },
          type: "chat",
        });
      } else if (response.status === 400) {
        this.$message.error(response.msg);
      }
    },
    uploadError(error) {
      this.$message.error(error);
    },
  },
};
</script>

<style lang="less" scoped>
.room {
  border-radius: 10px;
  position: fixed;
  top: calc(50% - 280px);
  left: calc(50% - 265px);
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 630px;
  height: 554px;
  background-color: #ffffff;
  overflow: hidden;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
}
.chat-room {
  .room {
    &.win {
      top: 10%;
      left: 50%;
      width: 20vw;
      margin-left: -10vw;
      height: 50vh;
    }

    .head {
      display: flex;
      align-items: center;
      height: 50px;
      padding-right: 15px;
      padding-left: 20px;
      background: linear-gradient(270deg, #1890ff 0%, #3875ea 100%);

      .image {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        flex: 1;
        min-width: 0;
        margin-left: 15px;
        font-size: 16px;
        color: #ffffff;
      }

      .iconfont {
        width: 25px;
        height: 25px;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .main {
      flex: 1;
      display: flex;
      min-height: 0;

      .chat {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-width: 0;
      }

      .record {
        flex: 1;
        min-height: 0;
        overflow-x: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
          // width: 6px;
          // height: 30px;
          // background-color: #888; /* 设置滚动条滑块的颜色 */
          // border-radius: 6px; /* 设置滚动条滑块的圆角 */
        }

        ul {
          padding: 20px;
        }

        li {
          ~ li {
            margin-top: 20px;
          }

          &::after {
            content: "";
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
          }

          &.right {
            .avatar {
              float: right;
            }

            .content {
              text-align: right;

              > div {
                text-align: left;
              }
            }
          }
        }

        .time-tag {
          padding-top: 10px;
          padding-bottom: 30px;
          text-align: center;
          color: #999999;
        }

        .avatar {
          float: left;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;

          &.right {
            float: right;
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .content {
          margin-right: 56px;
          margin-left: 56px;
        }

        .text {
          display: inline-block;
          // min-height: 41px;
          padding: 10px 12px;
          border-radius: 10px;
          background-color: #f5f5f5;
          font-size: 15px;
          line-height: 21px;
          color: #000000;
          word-break: break-all;
        }

        .image {
          display: inline-block;
          max-width: 50%;
          border-radius: 10px;
          overflow: hidden;

          img {
            display: block;
            max-width: 100%;
          }
        }

        .goods,
        .order {
          display: inline-flex;
          align-items: center;
          width: 320px;
          padding: 10px 13px;
          border-radius: 10px;
          background-color: #f5f5f5;
        }

        .thumb {
          width: 60px;
          height: 60px;
          border-radius: 5px;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        .intro {
          flex: 1;
          min-width: 0;
          margin-left: 10px;

          .name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            color: #000000;
          }

          .attr {
            margin-top: 5px;
            font-size: 12px;
            color: #999999;

            span {
              vertical-align: middle;

              ~ span {
                margin-left: 10px;
              }
            }
          }

          .group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;

            .money {
              font-size: 14px;
              color: #ff0000;
            }

            a {
              font-size: 12px;
              color: #1890ff;
            }
          }
        }
      }

      .editor {
        display: flex;
        flex-direction: column;
        height: 162px;
        border-top: 1px solid #ececec;

        > div {
          &:first-child {
            font-size: 0;
          }
        }

        button {
          border: none;
          background: none;
          outline: none;

          ~ button {
            margin-left: 20px;
          }

          &.end {
            font-size: 15px;
          }

          &:hover {
            color: #1890ff;

            .iconfont {
              color: #1890ff;
            }
          }
        }

        .editor-hd {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          padding-right: 20px;
          padding-left: 20px;

          .iconfont {
            line-height: 1;
            color: #333333;
          }

          .emoji-panel {
            position: absolute;
            bottom: 100%;
            left: 5px;
            width: 390px;
            padding-bottom: 10px;
            border: 1px solid #ececec;
            margin-bottom: 5px;
            background-color: #ffffff;
            box-shadow: 1px 0 16px 0 rgba(0, 0, 0, 0.05);

            .em {
              width: 28px;
              height: 28px;
              padding: 4px;
              margin-top: 10px;
              margin-left: 10px;
              box-sizing: border-box;

              &:hover {
                background-color: #ececec;
              }
            }
          }
        }

        .icon-biaoqing1,
        .icon-tupian1 {
          font-size: 22px;
        }

        .icon-guanji {
          margin-right: 5px;
          font-size: 15px;
        }

        .editor-bd {
          flex: 1;
          min-height: 0;

          textarea {
            display: block;
            width: 100%;
            height: 100%;
            padding-right: 20px;
            padding-left: 20px;
            border: none;
            outline: none;
            resize: none;
            white-space: pre-wrap;
            overflow-wrap: break-word;

            &::-webkit-scrollbar {
              display: none;
            }
          }
        }

        .editor-ft {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20px;
          padding-bottom: 20px;

          button {
            width: 68px;
            height: 26px;
            border: none;
            border-radius: 3px;
            background-color: #3875ea;
            outline: none;
            font-size: 13px;
            color: #ffffff;

            &:disabled {
              background-color: #cccccc;
            }
          }
        }
      }

      .notice {
        display: flex;
        flex-direction: column;
        width: 260px;
        border-left: 1px solid #ececec;

        .rich {
          flex: 1;
          min-height: 0;
          padding: 18px 18px 0;
          overflow-x: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          /deep/ img {
            width: 100%;
          }

          /deep/ video {
            width: 100%;
          }
        }

        .copy {
          padding-top: 15px;
          padding-bottom: 15px;
          font-size: 12px;
          text-align: center;
          a {
            color: #cccccc !important;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
