<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">关于我们</div>
      <div class="con"><span> CS2SKINS是一个为CS2游戏玩家提供丰富、安全的开箱体验的网站。我们拥有丰富的CS2饰品，爆率良心，提取服务快捷安全。我们致力于建立CS2饰品娱乐社区，为用户提供更多趣味性更强的CS2饰品服务和游戏体验。
我们的优势在于我们拥有大量的、最新的、种类繁多的CS2饰品和CS2饰品箱子。我们的提取服务也十分快捷安全，使用Steam机器人自动完成。需要注意的是我们提供的CS2饰品所标注的价格仅仅是当前我们采购该饰品的参考成本价格，由于饰品价格和库存随时受市场波动影响，本站展示的饰品价格与市场有一定的差异。并且，饰品价格并不代表我们对其价值的认同，也不代表该饰品真正的价值，请用户理性对待。
我们还提醒用户不要轻信任何形式的诱导充值、私下交易等行为，以免造成人身或财产损失。我们仅通过本站公告和qq在线客服向用户发布重要通知和解决用户遇到的问题，我们不会主动通过电话、短信、添加游戏好友、微信好友等任何方式向用户促销或发起其它任何骚扰行为。我们发货仅需要用户提供STEAM交易链接即可，我们不会以任何名义索要用户个人身份信息、游戏账号、密码、验证码等敏感信息。</span><br>
        <span>特别声明：本平台仅允许年满18周岁及以上者使用；本平台与Valve Corporation及其代理商均无任何关系。<br></span>
        <!-- <span>联系地址：301-309 Nathan Road, Yau Tsim Mong, Hong Kong</span><br> -->
        <p></p>
        <span>联系方式：</span><br>
        <!-- <span style="text-indent: 30px;">官方客服QQ：3887264450</span><br>
        <span style="text-indent: 30px;">官方客服群：383566592</span><br> -->
        <span style="text-indent: 30px;">官方邮件：cs2skins8@gmail.com</span><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    // padding-top: 88px;
    // background-color: #2b2c37;
    padding: 32px;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
</style>